<template>
  <modal name="product-info-modal">
    <div slot="header">
      <p class="h4 m0" v-if="info[text]">
        {{ info[text].title }}
      </p>
    </div>
    <div slot="content">
      <connection-codes v-if="text === 'connectionCode'" :prod="prod" />
      <div v-else-if="info[text]" v-html="info[text].body" />
    </div>
  </modal>
</template>

<script>
import Modal from 'theme/components/core/Modal';
import ConnectionCodes from '../ProductTabs/ConnectionCodes.vue'

export default {
  name: 'ProductInfoModal',
  components: {
    Modal,
    ConnectionCodes
  },
  props: {
    text: {
      type: String,
      required: true
    },
    prod: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      info: {
        connectionCode: {
          title: 'Kody podłączeń'
        },
        power75: {
          title: 'Moc grzewcza: 75/65/20°C',
          body: `<p>75°С - najczęściej osiągana temperatura wody w instalacjach grzewczych zasilanych siecią miejską oraz nowych kotłów na paliwo stałe/kotłów na gaz/olej – system wysokotemperaturowy
                 </br>
                 </br>
                 75/65/20°C
                 </br>
                 </br>
                 75°C - temperatura wody na wejściu do grzejnika</br>
                 65°C - temperatura wody na wyjścia z grzejnika</br>
                 20°C - temperatura jaka zostanie uzyskana w pomieszczeniu, przy założeniu, że moc grzejnika jest dobrana wg zasady: do ogrzewanie 1m2 pomieszczenia (wysokość ok 2,5m) należy użyć 60-90 W mocy grzejnej.</br>
                 </br>
                 </br>
                 Przykład: do ogrzania pomieszczenia o powierzchni 10m2 potrzebny jest grzejnik o mocy 600 -900 W.
                 Aby uzyskać temperaturę wyższą niż 20°C, należy zastosować grzejnik o większej mocy.</p>`
        },
        power70: {
          title: 'Moc grzewcza: 70/55/20°C',
          body: `<p>70°С - najczęściej osiągana temperatura wody w instalacjach grzewczych w nowych kotłach na paliwo stałe/kotłów na gaz/olej – system niskotemperaturowy
                 </br>
                 </br>
                 70/55/20°C
                 </br>
                 </br>
                 70°C - temperatura wody na wejściu do grzejnika</br>
                 55°C - temperatura wody na wyjścia z grzejnika</br>
                 20°C - temperatura jaka zostanie uzyskana w pomieszczeniu, przy założeniu, że moc grzejnika jest dobrana wg zasady: do ogrzewanie 1m2 pomieszczenia (wysokość ok 2,5m) należy użyć 60-90 W mocy grzejnej.</br>
                 </br>
                 </br>
                 Przykład: do ogrzania pomieszczenia o powierzchni 10m2 potrzebny jest grzejnik o mocy 600 -900 W. Aby uzyskać temperaturę wyższą niż 20°C, należy zastosować grzejnik o większej mocy.</p>`
        },
        power65: {
          title: 'Moc grzewcza : 65/50/20',
          body: `70°С - najczęściej osiągana temperatura wody w instalacjach grzewczych zasilanych dla nowoczesnych kotłów kondensacyjnych – w celu całorocznego wykorzystywania efektu kondensacji – system średniotemperaturowy
                </br>
                </br>
                70/55/20°C
                </br>
                </br>
                75°C - temperatura wody na wejściu do grzejnika</br>
                55°C - temperatura wody na wyjści z grzejnika</br>
                20°C - temperatura jaka zostanie uzyskana w pomieszczeniu, przy założeniu, że moc grzejnika jest dobrana wg zasady: do ogrzewanie 1m2 pomieszczenia (wysokość ok 2,5m) należy użyć 60-90 W mocy grzejnej.</br>
                </br>
                </br>
                Przykład: do ogrzania pomieszczenia o powierzchni 10m2 potrzebny jest grzejnik o mocy 600 -900 W.
                Aby uzyskać temperaturę wyższą niż 20°C, należy zastosować grzejnik o większej mocy.`
        },
        deliveryTime: {
          title: 'Termin dostawy / czas realizacji',
          body: `Większość produktów w AG Grzejniki Design realizowanych jest przez producentów na indywidualne zamówienie naszych Klientów.</br>
                 Dlatego do każdego produktu przypisany jest szacowany termin realizacji oraz wysyłki (uwzględniający czas wyprodukowania pod indywidualne zamówienie).</br>
                 Czas realizacji złożonego zamówienia zaczyna się od 1 do 2 dni roboczych po zaksięgowaniu pieniędzy na naszym koncie.</br>
                 Po wyprodukowaniu i otrzymaniu zamówionego towaru - kontaktujemy się z klientem w celu ustalenia dokładnego terminu dostawy. Dostawy realizowane są naszymi transportami lub firmami kurierskimi na terenie Polski.
                 </br>
                 </br>
                 W przypadku produktów będących już na stanie magazynowym, wysyłka następuje od 1 do 2 dni roboczych po zaksięgowaniu pieniędzy na naszym koncie. Przesyłki dostarczają firmy kurierskie a czas dostawy zależy od przewoźnika, rodzaju przesyłki oraz adresu odbiorcy. Zazwyczaj zajmuje to do 2 dni roboczych.</br>
                 Uwaga! Przy płatności przelewem internetowym, czas otrzymania paczki wydłuża się o czas realizacji przelewu, zalecamy płatność poprzez system Pay-U, PayPal lub przesyłkę za pobraniem.`
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
