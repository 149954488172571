<template name="ConnectionCodes">
  <div class="cl-black">
    <div class="row">
      <div class="col-xs-12">
        <h3 class="h4 weight-500 uppercase m0 pb10">
          {{ $t('Kody podłączeń hydraulicznych') }}
        </h3>
        <p class="m0 pb10">
          {{ $t('Możliwe dla danego modelu kody podłączeń znajdują się na stronie produktu') }}
        </p>
      </div>
      <div class="col-xs-6 col-sm-4 center-xs" v-for="(src, index) in getImagesArrSrc()" :key="index">
        <img :src="src" alt="kod podłączenia" width="200px" height="auto">
      </div>
    </div>
    <div class="row m0">
      <div class="col-xs-12" v-if="productBrand && !isHideCodes">
        {{ $t('Wszystkie kody podłaczeń dla marki ') + productBrand.name }}
      </div>
      <div v-if="productBrand && isHideCodes" @click="isHideCodes = false" class="col-xs-12 pointer cl-dark-blue center-xs mb20">
        Pokaż wszystkie kody przyłączeń dla producenta: {{ productBrand.name }} >
      </div>
      <div v-if="!isHideCodes" class="flex col-xs-6 col-sm-4 middle-xs center-xs pb10" v-for="(src, index) in getImagesArrSrc('all')" :key="index">
        <img :src="src" alt="kod podłączenia" width="200px" height="auto">
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'

export default {
  name: 'ConnectionCodes',
  mixins: [getLabelMixin],
  props: {
    prod: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      productBrand: null,
      isHideCodes: true
    }
  },
  methods: {
    getImagesArrSrc (type) {
      let allBrands = ['IRSAP', 'RADOX', 'ZEHNDER', 'AG MODERN']
      let allImagesByBrand = {
        'IRSAP': [ '18', '25', '50', 'B01', 'B10', 'B11', 'B12', 'B14', 'B15', 'B99_1', 'B99_2', 'B99_3', 'B99', 'M01', 'M02', 'M80', 'M82', 'M83', 'M84', 'M85', 'M87', 'M88', 'M92', 'M93', 'M99' ],
        'Radox': [ '12', '15', '25', '26', '34', '37', '65', '84', 'D45', 'D50', 'D50L', 'D50P', 'U', 'VKL', 'VKP' ],
        'Zehnder': ['1270', '1670', '3370', '3470', '3570', '5310', '5410', '5510', '7210', '7610', 'V001', 'V002', 'V007', 'V008'],
        'AG Modern': ['34', 'D50', '34_D50', 'D50L', 'D50P']
      }
      this.productBrand = this.prod.category.find(cat => allBrands.includes(cat.name.toUpperCase()))
      if (this.productBrand) {
        if (type === 'all' && allImagesByBrand[this.productBrand.name]) {
          return allImagesByBrand[this.productBrand.name].map(label => 'assets/svg/' + this.productBrand.name.toUpperCase() + '/' + this.productBrand.name + '_' + label + '.svg')
        }
        let codesLabels
        let customOption = this.prod.custom_options && this.prod.custom_options.find(option => option.title === 'connection_code')

        customOption
          ? codesLabels = customOption.values.map(value => value.title)
          : codesLabels = this.prod.connection_code ? this.prod.connection_code.map(id => this.getLabelforValue('connection_code', id)) : null

        codesLabels = codesLabels
          ? codesLabels
            .map(label => {
              if (label !== 'Inne') {
                return 'assets/svg/' + this.productBrand.name.toUpperCase() + '/' + this.productBrand.name + '_' + label.replace('/', '_') + '.svg'
              }
            })
            .filter(x => x)
          : null
        this.$store.commit('ui/changeImageResultResponse', codesLabels)
        return codesLabels
      }
      this.$store.commit('ui/changeImageResultResponse', null)
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden-codes {
  max-height: 330px;
  overflow: hidden;
}
</style>
